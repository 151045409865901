import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { Pipe, PipeTransform } from '@angular/core';

dayjs.extend(relativeTime);

@Pipe({
  name: 'timeSince',
  standalone: true,
})
export class TimeSincePipe implements PipeTransform {
  transform(value: string | number | undefined): string {
    return dayjs(value).fromNow();
  }
}
