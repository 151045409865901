import { ROUTER_UTILS } from './router.utils';

export const encodeParam = (param: string): string => {
  return encodeURIComponent(param);
};

export const decodeParam = (param: string): string => {
  return decodeURIComponent(param);
};

export const ROUTE_FROM = {
  jobApply: 'job-apply-form',
  editPlaylist: 'edit-playlist-form',
  createPlaylist: 'create-playlist-form',
};

export const ROUTE_FROM_MAP: { [key: string]: string[] } = {
  [ROUTE_FROM.jobApply]: [
    ROUTER_UTILS.candidate.root,
    ROUTER_UTILS.candidate.dashboard.jobs.root,
    ROUTER_UTILS.candidate.dashboard.jobs.jobApply,
  ],
  [ROUTE_FROM.editPlaylist]: [
    ROUTER_UTILS.candidate.root,
    ROUTER_UTILS.candidate.dashboard.playlist.root,
    ROUTER_UTILS.candidate.dashboard.playlist.edit,
  ],
  [ROUTE_FROM.createPlaylist]: [
    ROUTER_UTILS.candidate.root,
    ROUTER_UTILS.candidate.dashboard.playlist.root,
    ROUTER_UTILS.candidate.dashboard.playlist.createPlaylist,
  ],
};
